import React from "react";
import moment from "moment";
import commafy from "../utils/commafy";
import jsPDF from "jspdf";
import { Button } from "evergreen-ui";

const Invoice = ({ client, invoiceItems, invoice, owner, print }) => {
  const ref = React.useRef(null);
  const {
    address1,
    address2,
    company,
    country,
    email,
    firstName,
    middleName,
    lastName,
    phone,
    local,
  } = client;
  const { invoiceNumber, invoiceDate, dueDate, currency, tvsh, vat } = invoice;

  const savePdf = async () => {
    var pdf = new jsPDF({
      compress: true,
    });
    pdf.canvas.height = 100 * 11;
    pdf.canvas.width = 100 * 8.5;

    await pdf.html(ref.current, {
      margin: 1,
      image: { type: "jpeg", quality: 0.4, compression: "SLOW" },
      x: 10,
      y: 10,
      html2canvas: {
        scale: 0.238,
      },
      callback: function (doc) {
        doc.save((invoiceNumber||"001") + ".pdf");
      },
    });
  };

  return (
    <>
      <div style={print ? {visibility:"hidden",height:0,overflow: "hidden", width:"55.5%", minWidth:800,maxWidth:800}:{}}>
      <div className="invoice-wrapper" ref={ref} >
        <div className="invoice  preview" style={{ border: 0 }}>
          <div className="invoice-company-details">
            <div className="invoice-logo has-image">
              {owner.logo && <img src={owner.logo} alt="logo" />}
            </div>

            <div className="invoice-type editable-area">Invoice</div>
          </div>

          <div className="sender-and-recipient">
            <div className="invoice-sender invoice-contact-info">
              <div className="invoice-title">From</div>

              <div className="invoice-sender-details">
                <div className="sender-name">{owner.company}</div>

                <div className="sender-address">
                  <div>{owner.address1}</div>
                  {owner.address2}
                </div>

                <div>{owner.email}</div>

                <div>{owner.phone}</div>

                <div>{owner.website}</div>

                <br />
                <div className="details-registration">
                  Tax Registration Number: {owner.taxNumber}
                </div>
                {Boolean(owner.vatNumber) && <div className="details-registration">
                  VAT Number: {owner.vatNumber}
                </div>}
              </div>

              {/* <div className="details-company"></div> */}

              <div
                className="custom-field-area custom-field-area__sender"
                data-area="sender"
              ></div>
            </div>

            <div className="invoice-client">
              <div className="invoice-title">To</div>

              <div className="invoice-client-name">{company}</div>

              <div className="client-address">
                <div className="invoice-client-info">
                  {!middleName ? firstName : firstName + " " + middleName}{" "}
                  {lastName}
                </div>
                {address1 && (
                  <div className="invoice-client-info">{address1}</div>
                )}
                {address2 && (
                  <div className="invoice-client-info">{address2}</div>
                )}
                {country}
              </div>

              <div className="invoice-client-info">{email}</div>

              {phone && <div className="invoice-client-info">{phone}</div>}

              <div
                className="custom-field-area custom-field-area__receiver"
                data-area="receiver"
              ></div>
            </div>
          </div>
          {local && (
            <div className="details-description">
              Beneficiary: Coetic X LLC <br />
              Beneficiary's Bank: Banka Kombetare Tregtare SHA <br />
              Bank Address: Rr. Ukshin Hoti, Nr.29, Qyteza Pejton, Prishtine
              Kosove <br />
              Swift Code: NCBAXKPR <br />
              IBAN Code: XK05 1902 0041 3703 1143 <br />
            </div>
          )}

          {client.label === "Dearest" && (
            <div className="details-description">
              Recipient: Coetic X <br />
              Account: LT023500010016904672 <br />
              SWIFT: EVIULT2VXXX <br />
              Bank name: Paysera LT, UAB <br />
              Bank address: Pilaitės pr. 16, Vilnius,
              LT-04352, Lithuania
            </div>
          )}

          <div
            className="custom-field-area custom-field-area__description"
            data-area="description"
          ></div>

          <div className="invoice-details">
            <div className="invoice-meta">
              <div>
                <span>Invoice No.</span>
                {invoiceNumber}
              </div>
              <div>
                <span>Invoice Date</span>

                <div className="date-outer">
                  {moment(invoiceDate).format("MMM Do, YYYY")}
                </div>
              </div>
            </div>

            <div className="invoice-due-date">
              <div className="due-text">Due:</div>
              <div className="due-date">
                {moment(dueDate).format("MMM Do, YYYY")}
              </div>
            </div>
          </div>

          <ul className="line-items">
            <li className="invoice-items-header">
              <div className="invoice-item">Item</div>
              <div className="invoice-quantity">HRS/QTY</div>
              <div className="invoice-rate">Rate</div>
              <div className="invoice-tax-rate">Tax</div>
              <div className="invoice-subtotal">Subtotal</div>
            </li>
            {invoiceItems.map((item) => (
              <li className="line-item" data-id="870691" data-cid="">
                <div className="line-item__top">
                  <div className="invoice-item">{item.title}</div>
                  <div className="invoice-quantity">{item.quantity}</div>
                  <div className="invoice-rate">{commafy(parseFloat(local? item.rate / 1.18 :item.rate).toFixed(2))}</div>
                  <div className="invoice-tax-rate"></div>
                  {/* <div className="invoice-subtotal">USD 5,479.50</div> */}
                  <div className="invoice-subtotal">
                    {currency}{" "}
                    {commafy(parseFloat(local ? item.quantity * item.rate/ 1.18 :item.quantity * item.rate).toFixed(2))}
                  </div>
                  <div className="line-item-actions"></div>
                </div>

                <div className="invoice-description">{item.description}</div>
              </li>
            ))}
          </ul>

          <div className="invoice-summary">
            <div>

            <div className="invoice-summary-inner">
              <div className="summary-header invoice-row">
                <span className="invoice-type-text">Invoice</span> Summary
              </div>

              <div className="invoice-row">
                <div>Subtotal</div>
                <div>
                  {currency}{" "}
                  {commafy(
                    parseFloat(String(
                      local ? invoiceItems.reduce(
                        (a, b) => +a + +(b.rate * b.quantity),
                        0
                      )/1.18 : invoiceItems.reduce(
                        (a, b) => +a + +(b.rate * b.quantity),
                        0
                      )
                    )).toFixed(2)
                  )}
                </div>
              </div>

              {Boolean(owner.vatNumber) &&(
              <div className="invoice-row">
                <div>VAT</div>
                <div>{vat || local ? (
                  <>
                  {currency}{" "}
                  {commafy(
                    parseFloat(String(local? invoiceItems.reduce(
                      (a, b) => +a + +(b.rate * b.quantity),
                      0
                    )/1.18*.18:vat)).toFixed(2)
                  )}
                  </>
                ): "-"}
                </div>
              </div>)}

              <div id="invoice-tax-line-items-wrapper">
                <div className="invoice-row total-taxes">
                  <div className="col-4">Tax</div>
                  <div className="tax-value summary-value col-8">{local ?
                  <>
                  {currency}{" "}
                  {commafy(
                    parseFloat(String(invoiceItems.reduce(
                      (a, b) => +a + +(b.rate * b.quantity),
                      0
                    )*0.18)).toFixed(2)
                  )}
                  </>  :'-'}</div>
                </div>
              </div>
              <div className="invoice-row total-row">
                <div>Total</div>
                <div className="invoice-total">
                  {currency}{" "}
                  {commafy(
                    (parseFloat(String(
                      invoiceItems.reduce(
                        (a, b) => +a + +(b.rate * b.quantity),
                        0
                      )
                    ))+ parseFloat(String(vat || 0))).toFixed(2)
                  )}
                </div>
              </div>
              <div className="payments-wrapper"></div>
            </div>
               
            </div>      
             {Boolean(tvsh)&& <div className="invoice-summary-inner tvsh"><p>{owner.tvsh}</p></div> }
          </div>

          <div className="invoice-terms"></div>

          <div
            className="custom-field-area custom-field-area__terms"
            data-area="terms"
          ></div>
        </div>
      </div>
      </div>
      {Boolean(print) && (<Button appearance="primary" size="large" style={{position:'fixed', bottom:10, right:10}} onClick={savePdf}>Print</Button>)}
    </>
  );
};

export default Invoice;
