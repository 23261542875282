import React from "react"
import { Route, BrowserRouter as Router } from "react-router-dom"
import Home from "./pages/Home"
import Landing from "./pages/Landing"
import Login from "./pages/Login"
import Register from "./pages/Register"

const App = () => {

  return (
    <Router>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login}/>
        <Route path="/register" component={Register}/>
        <Route path="/landing" component={Landing}/>
    </Router>
  )
}
export default App;