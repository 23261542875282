const { Table, Button } = require("evergreen-ui")
const { default: commafy } = require("../utils/commafy")

const InvoiceTable = ({invoiceItems, currency, setInvoiceItems}) => {
    return (
        <Table>
        <Table.Head height={26}>
          <Table.TextHeaderCell>Item</Table.TextHeaderCell>
          <Table.TextHeaderCell>HRS/QTY</Table.TextHeaderCell>
          <Table.TextHeaderCell>Rate</Table.TextHeaderCell>
          <Table.TextHeaderCell>Subtotal</Table.TextHeaderCell>
          <Table.TextHeaderCell
            flexGrow={0}
            flexBasis={40}
            flexShrink={1}
          ></Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {invoiceItems.map((item, i) => (
            <Table.Row key={item.id} height={32}>
              <Table.TextCell>
                {item.title} <br />
                {item.description}
              </Table.TextCell>
              <Table.TextCell isNumber>{item.quantity}</Table.TextCell>
              <Table.TextCell isNumber>{item.rate}</Table.TextCell>
              <Table.TextCell isNumber>
                {currency}{" "}
                {commafy(
                  parseFloat(item.quantity * item.rate).toFixed(2)
                )}
              </Table.TextCell>
              <Table.TextCell
                flexGrow={0}
                flexBasis={40}
                flexShrink={1}
                style={{ padding: 0 }}
              >
                <Button
                  appearance="minimal"
                  onClick={() =>
                    setInvoiceItems((xx) =>
                      xx.filter((a) => a.id !== item.id)
                    )
                  }
                >
                  X
                </Button>
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    
    )
}
export default InvoiceTable