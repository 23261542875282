const clients = [
  {
    value: "0",
    address1: "26 Broadway, Level 8",
    address2: "New York, NY 10004",
    label: "Upstack Technologies Inc.",
    company: "Upstack Technologies Inc.",
    country: "United States of America",
    email: "accounts@upstack.co",
    firstName: "Joseph",
    middleName: "Andreas",
    lastName: "Mlynsky",
    phone: "+1 917-972-1356",
    currency: "USD",
    // rate: 50,
  },
  {
    value: "1",
    address1:
      "9 Great Chesterford Court, London Road, Great Chesterford - CB10 1PF",
    address2: null,
    label: "Changing Digital Limited",
    company: "Changing Digital Limited",
    country: "United Kingdom",
    email: "contact@changingdigital.com",
    firstName: "Henry",
    middleName: null,
    lastName: "Walker",
    phone: null,
    currency: "GBP",
    // rate: 0,
  },
  {
    value: "2",
    address1: "Erich-Thilo-Str. 10 12489 Berlin",
    address2: null,
    label: "Dearest",
    company: "Dearest Life GmbH",
    country: "Germany",
    email: null,
    firstName: "Johannes",
    middleName: null,
    lastName: "Klein",
    phone: "+49 6134 5006130",
    local: false,
    currency: "EUR",
    // rate: 28.1,
  },
  {
    value: "3",
    address1: "1390 Market Street, Suite 200 San Francisco",
    address2: "California 94102",
    label: "Povio Inc.",
    company: "Povio Inc.",
    country: "United States of America",
    email: "ap@poviolabs.com",
    firstName: "Matevž",
    middleName: null,
    lastName: "Petek",
    phone: null,
    currency: "USD",
    // rate: 44,
  },
  // {
  //   value: "4",
  //   address1: "Metush Krasniqi n.30",
  //   address2: "Prishtina 10000, Kosovo",
  //   label: "Coetic X, LLC",
  //   company: "Coetic X, LLC",
  //   country: "Tax Registration Number - 811569089",
  //   email: "arber@coetic-x.com",
  //   firstName: "Arber",
  //   middleName: "",
  //   lastName: "Shabani",
  //   phone: "+383 45 250 180",
  //   currency: "USD",
  //   rate: 37.5,
  // },
  // {
  //   // value: "5",
  //   value: "4",
  //   address1: "55 E Monroe St #2120",
  //   address2: "Chicago, IL 60603",
  //   label: "RemoteMore USA Inc.",
  //   company: "RemoteMore USA Inc.",
  //   country: "United States of America",
  //   email: "boris.krastev@remotemore.com",
  //   firstName: "Boris",
  //   middleName: null,
  //   lastName: "Krastev",
  //   phone: null,
  //   currency: "EUR",
  //   // local: true,
  //   // rate: 25,
  // },
  {
    // value: "5",
    value: "4",
    address1: "no. 5/18",
    address2: "Struga",
    label: "Orange Melons",
    company: "Orange Melons DOO",
    country: "MKD",
    email: "",
    firstName: "ul. Dimce Kovacevski, ",
    middleName: null,
    lastName: "",
    phone: "",
    currency: "EUR",
    // local: true,
    // rate: 25,
  },
  // {
  //   value: "5",
  //   // value: "6",
  //   address1: "Neuhofstrasse 22",
  //   address2: "6340 Baar",
  //   label: "Accointing AG",
  //   company: "Accointing AG",
  //   country: "Switzerland",
  //   email: "can@accointing.com",
  //   firstName: "Can",
  //   middleName: null,
  //   lastName: "Kattwinkel",
  //   phone: null,
  //   currency: "USD",
  //   // local: true,
  //   // rate: 44.275,
  // },
  {
    value: "5",
    // value: "6",
    address1: "811963223",
    address2: "Fushë Kosovë, Rruga Arbëria, B25",
    label: "Rockapps",
    company: "Rockapps L.L.C.",
    country: "Kosovë",
    email: "enisjusufii@gmail.com",
    firstName: "Enis",
    middleName: null,
    lastName: "Jusufi",
    phone: '049 802 206',
    currency: "EUR",
    local: true,
    // local: true,
    // rate: 44.275,
  },
  {
    value: "6",
    // value: "6",
    address1: "Suite 104 PMB 89532",
    address2: "San Antonio, TX. 72858-4946",
    label: "",
    company: "Gigster, LLC",
    country: "USA",
    email: null,
    firstName: "21750 Hardy Oak Blvd,",
    middleName: null,
    lastName: "",
    phone: null,
    currency: "USD",
    // local: true,
    // rate: 44.275,
  },
];

export default clients;
