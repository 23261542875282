import React from "react";
import Navbar from "../layouts/Navbar";


const Landing = ()=> {
    return (
        <>
            <Navbar/>
        
        </>
    )
}
export default Landing