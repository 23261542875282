import React, { useEffect } from "react";
import "../App.css";
import { Button, Checkbox, SegmentedControl, TextInput } from "evergreen-ui";
import Invoice from "../Invoice/Invoice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import clients from "../data/clients";
import owners from "../data/owners";
import currencies from "../data/currencies";
import InvoiceTable from "../Invoice/_Table";

const Home = () => {
  const [value, setValue] = React.useState("0");
  const [currency, setCurrency] = React.useState("USD");
  const [owner, setOwner] = React.useState("0");

  const [invoiceDate, setInvoiceDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [invoiceItems, setInvoiceItems] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [rate, setRate] = React.useState("");
  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const [tvsh, setTvsh] = React.useState(false);
  const [vat, setVat] = React.useState("");

  useEffect(()=>{
    setTvsh(Boolean(owners[owner].tvsh))
  },[owner])

  const addInvoiceItem = () => {
    setInvoiceItems((i) => [
      ...i,
      { id: Math.random() * 10000, title, description, quantity, rate },
    ]);
    setTitle("");
    setDescription("");
    setQuantity("");
    setRate("");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: 10,
        }}
      >
        <div
          style={{
            width: "41%",
            display: "flex",
            flexDirection: "column",
            padding: 40,
            border: "1px solid rgba(0,0,0,0.08)",
            marginLeft: "6%",
          }}
        >
          <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
            Invoice Owner:{" "}
          </div>
          <SegmentedControl
            size="medium"
            options={owners}
            value={owner}
            onChange={(value) => {
              setOwner(value);
              if (value === "1") {
                setValue("4");
              } else if (value === "0") {
                setValue("0");
              }
              setCurrency(owners[value].currency);
              if (owners[value].rate) {
                setRate(owners[value].rate);
              } else {
                setRate("");
              }
            }}
          />
          <hr />
          <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
            Invoice Client:{" "}
          </div>
          <SegmentedControl
            size="medium"
            options={clients.slice(0, 3)}
            value={value}
            onChange={(value) => {
              setValue(value);
              setCurrency(clients[value].currency);
              if (clients[value].rate) {
                setRate(clients[value].rate);
              } else {
                setRate("");
              }
            }}
          />
          <SegmentedControl
            size="medium"
            options={clients.slice(3, 6)}
            value={value}
            onChange={(value) => {
              setValue(value);
              setCurrency(clients[value].currency);
              if (clients[value].rate) {
                setRate(clients[value].rate);
              } else {
                setRate("");
              }
            }}
          />
          {/* <SegmentedControl
            size="medium"
            options={clients.slice(6,9)}
            value={value}
            onChange={(value) => {
              setValue(value);
              setCurrency(clients[value].currency);
              if (clients[value].rate) {
                setRate(clients[value].rate);
              } else {
                setRate("");
              }
            }}
          /> */}
          <hr />

          <div style={{ justifyContent: "center" }}>
            <div style={{ display: "flex", margin: 10, width: "50%" }}>
              <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
                Invoice Date:{" "}
              </div>
              <DatePicker
                className="css-12rdf6g ub-w_280px ub-fnt-fam_b77syt ub-b-btm_1px-solid-transparent ub-b-lft_1px-solid-transparent ub-b-rgt_1px-solid-transparent ub-b-top_1px-solid-transparent ub-otln_iu2jf4 ub-txt-deco_none ub-bblr_4px ub-bbrr_4px ub-btlr_4px ub-btrr_4px ub-ln-ht_16px ub-fnt-sze_12px ub-color_474d66 ub-pl_12px ub-pr_12px ub-tstn_n1akt6 ub-h_32px ub-bg-clr_white ub-b-btm-clr_d8dae5 ub-b-lft-clr_d8dae5 ub-b-rgt-clr_d8dae5 ub-b-top-clr_d8dae5 ub-box-szg_border-box"
                selected={invoiceDate}
                onChange={(date) => setInvoiceDate(date)}
              />
            </div>

            <div style={{ display: "flex", margin: 10, width: "50%" }}>
              <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
                Due Date:{" "}
              </div>
              <DatePicker
                className="css-12rdf6g ub-w_280px ub-fnt-fam_b77syt ub-b-btm_1px-solid-transparent ub-b-lft_1px-solid-transparent ub-b-rgt_1px-solid-transparent ub-b-top_1px-solid-transparent ub-otln_iu2jf4 ub-txt-deco_none ub-bblr_4px ub-bbrr_4px ub-btlr_4px ub-btrr_4px ub-ln-ht_16px ub-fnt-sze_12px ub-color_474d66 ub-pl_12px ub-pr_12px ub-tstn_n1akt6 ub-h_32px ub-bg-clr_white ub-b-btm-clr_d8dae5 ub-b-lft-clr_d8dae5 ub-b-rgt-clr_d8dae5 ub-b-top-clr_d8dae5 ub-box-szg_border-box"
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
              />
            </div>

            <div style={{ display: "flex", margin: 10 }}>
              <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
                Invoice Nr:{" "}
              </div>

              <TextInput
                type="string"
                placeholder="Invoice Nr: "
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </div>
            <hr />
            <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
              Invoice Item:{" "}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextInput
                  type="string"
                  placeholder="Title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ marginRight: 10, width: 220 }}
                />
                <TextInput
                  type="string"
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{ marginRight: 10, width: 220 }}
                />
              </div>
              <TextInput
                type="number"
                placeholder="HRS/QTY"
                name="quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                style={{ width: 100, marginRight: 10 }}
              />
              <TextInput
                type="number"
                placeholder="Rate"
                name="rate"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                style={{ width: 100, marginRight: 10 }}
              />
              <Button onClick={addInvoiceItem}>+</Button>
            </div>
            <br />
            {Boolean(invoiceItems.length) && (
              <InvoiceTable
                invoiceItems={invoiceItems}
                currency={currency}
                setInvoiceItems={setInvoiceItems}
              />
            )}
            <hr />
            <div style={{ display: "flex", margin: "0 10px", alignItems:"center" }}>
              <div style={{ minWidth: 100, height: 32, lineHeight: "32px" }}>
                Currency:{" "}
              </div>
              <SegmentedControl
                width={240}
                // height={32}
                options={currencies}
                value={currency}
                onChange={(value) => setCurrency(value)}
              />
              <Checkbox
                marginTop={0}
                marginBottom={0}
                marginLeft="auto"
                label="TVSH"
                checked={tvsh}
                onChange={e => setTvsh(e.target.checked)}
              />
            </div>
            <hr />
            <div style={{ display: "flex", margin: 10, width: "50%" }}>
              <div style={{ minWidth: 120, height: 32, lineHeight: "32px" }}>
                Value Added Tax:{" "}
              </div>
              <TextInput
                type="number"
                placeholder="VAT"
                name="vat"
                value={vat}
                onChange={(e) => setVat(e.target.value)}
                style={{ width: 100, marginRight: 10 }}
              />
            </div>
          </div>
          <br />
        </div>
        <div
          style={{
            width: "45%",
            margin: "0 6%",
            border: "1px solid rgba(0,0,0,0.08)",
          }}
        >
          <Invoice
            owner={owners[owner]}
            client={clients[value]}
            invoice={{ invoiceNumber, invoiceDate, dueDate, currency, tvsh, vat }}
            invoiceItems={invoiceItems}
          />
        </div>
      </div>
      <Invoice
        owner={owners[owner]}
        client={clients[value]}
        invoice={{ invoiceNumber, invoiceDate, dueDate, currency, tvsh }}
        invoiceItems={invoiceItems}
        print={true}
      />
    </>
  );
};

export default Home;
