
import logo from "../logo.png";
const owners = [
    {
      value: "0",
      label: "Coetic X",
      logo: logo,
      company: "Coetic X",
      address1: "Arberi, Rr. Ahmet Krasniqi, 5-1",
      address2: "Prishtina 10000, Kosovo",
      taxNumber: "811569089",
      email: "arber@coetic-x.com",
      website: "https://coetic-x.com",
      firstName: "Arber",
      middleName: null,
      lastName: "Shabani",
      phone: "+383 45 250 180",
      tvsh: "Reverse charge procedure as referred in sub-paragraph 1.2, 1.3 and 1.4 of Article 52 of the Kosovo Law No. 05/L-037 on Value Added Tax",
      vatNumber: "330492421",
    },
    // {
    //   value: "1",
    //   label: "Alex",
    //   logo: null,
    //   company: "Alex Hong",
    //   address1: "12/F Block B Mp Industrial Centre No.18 Ka Yip Street",
    //   address2: "Chai Wan, Hong Kong (SAR), 999077",
    //   taxNumber: "X5942506",
    //   email: "keifuchigami86@gmail.com",
    //   website: null,
    //   firstName: "Alex",
    //   middleName: null,
    //   lastName: "Hong",
    //   phone: "+13155441113",
    // },
  ];

  export default owners