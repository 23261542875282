import { Button, Pane, TextInputField } from "evergreen-ui"
import React, { useState } from "react"


const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    return (
        <Pane minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
            <Pane width="300px" display="flex" flexDirection="column" alignItems="center" >

            <TextInputField
                width="100%"
                label="Username"
                placeholder=""
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <TextInputField
                width="100%"
                label="Password"
                type="password"
                placeholder=""
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <Button type="primary">Login</Button>
            </Pane>
        </Pane>
    )
}
export default Login