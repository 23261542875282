import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import "./Navbar.scss"

const Navbar = () => {
    const [active, setActive] = useState(false);
    return (
        <>
           <nav className="Nav">
            <Link to="/" className="NavLogo">
                Logo
            </Link>
            {/* <Bars /> */}
            <button className="Bars"  onClick={()=> setActive(!active)}>
                <img src="./Bars.svg" alt="bars" />
            </button>

            <div className={"NavMenu" + (active?' active': '')}>
                <Link className="NavLink" to="/" activeStyle>
                    Home
                </Link>
                <Link className="NavLink" to="/about" activeStyle>
                    About
                </Link>
                <Link className="NavLink" to="/contact" activeStyle>
                    Contact
                </Link>
                <Link className="NavLink" to="/signin" activeStyle>
                    Sign In
                </Link>
                <nav className="NavBtn">
                    <Link className="NavBtnLink" to="/sign-up">Sign Up</Link>                
                </nav>
            </div> 
           </nav> 
        </>
    );
};
export default Navbar;